import React from 'react';
import CustomRoutes from './router';
import 'antd/dist/antd.css';

function App() {
    return (
        <CustomRoutes />
    );
}

export default App;
